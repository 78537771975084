import React, { Component } from "react";
import parse from "html-react-parser";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import JoditEditorContainer from "./JoditEditorContainer";
import createDOMPurify from "dompurify";
import { parseHTML } from "linkedom";
import SVG from "react-inlinesvg";
import { withRouter } from "react-router-dom";

const { window } = parseHTML("");
const DOMPurify = createDOMPurify(window);

class TemplateParser extends Component {
  editButtonWidthRef = React.createRef();
  containerWidthRef = React.createRef();

  state = {
    modal: false,
    uid: this.props.uid,
    placeholder: null,
    originalValue: null,
    modifiedValue: null,
    originalValueType: null,
    modifiedValueType: null,
    originalValueImage: null,
    modifiedValueImage: null,
    isGroupdoc: false,
    containerWidth: 0,
  };

  toggle = (placeholder, originalValue, originalValueType) => {
    if (originalValueType === "text") {
      this.setState({
        modal: !this.state.modal,
        placeholder: placeholder,
        originalValue: originalValue,
        modifiedValue: originalValue,
        originalValueType: originalValueType,
        modifiedValueType: originalValueType,
        originalValueImage: "",
        modifiedValueImage: "",
      });
    } else if (originalValueType === "image") {
      this.setState({
        modal: !this.state.modal,
        placeholder: placeholder,
        originalValueImage: originalValue,
        modifiedValueImage: originalValue,
        originalValueType: originalValueType,
        modifiedValueType: originalValueType,
        originalValue: "",
        modifiedValue: "",
      });
    } else {
      this.setState({
        modal: !this.state.modal,
        placeholder: placeholder,
        originalValue: "",
        modifiedValue: "",
        originalValueImage: "",
        modifiedValueImage: "",
        originalValueType: "text",
        modifiedValueType: "text",
      });
    }
  };

  componentDidMount() {
    let containerWidth = this.containerWidthRef.current?.offsetWidth;
    this.setState({ containerWidth: Number(containerWidth) });
    let content = JSON.parse(this.props.content);
    for (let item of Object.values(content)) {
      if (item?.type === "text" && item?.value?.search("<svg") !== -1) {
        this.setState({ isGroupdoc: true });
      }
    }
  }

  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  saveModal1 = () => {
    let update = null;
    let value = null;
    value = this.state.originalValue;
    update = {
      originalValue: this.state.originalValue,
      originalValueType: this.state.modifiedValueType,
    };
    this.setState(update, () => {
      this.props.onContentChangeHandler(
        this.state.uid,
        this.state.placeholder,
        value,
        this.state.originalValueType
      );
      this.closeModal();
      if (this.props.tempToggleModal) {
        this.props.tempToggleModal(true);
        setTimeout(() => {
          this.props.tempToggleModal(false);
        }, 50);
      }
    });
  };

  onChangeHandler = (value) => {
    this.setState({
      [this.state.modifiedValueType === "text"
        ? "modifiedValue"
        : "modifiedValueImage"]: value,
    });
  };

  onContentChangeTypeHandler = (e) => {
    this.setState({
      modifiedValueType: e,
    });
  };

  emptyRemainingSlugs = (replacedTemplate) => {
    const paramsPattern = /[^{}]+(?=})/g;
    let extractParams = replacedTemplate.match(paramsPattern);
    if (extractParams && extractParams.length > 0) {
      extractParams.forEach((param) => {
        replacedTemplate = replacedTemplate.replace(
          "{" + param + "}",
          "&nbsp;"
        );
      });
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(replacedTemplate, "text/html");
    doc.querySelectorAll(".row").forEach(function (row) {
      if (!row.querySelector("custom")) {
        // Checks if a 'custom' tag is not present in the child
        row.classList.add("d-none"); // Adds 'd-none' class if no 'custom' tag is found
      }
    });
    replacedTemplate = doc.body.innerHTML;
    return replacedTemplate;
  };

  styleConverter = (s) => {
    let style = {};
    let properties = s.split(";");
    properties.forEach((p) => {
      let propertyNames = p.split(":");
      if (propertyNames && propertyNames.length > 0)
        style[
          propertyNames[0].replace(/-([a-z])/g, function (g) {
            return g[1].toUpperCase();
          })
        ] = propertyNames[1];
    });
    return style;
  };

  renderSvgContent = (buttonWidth, svg_string) => {
    // let location = this.props.history.location.pathname?.includes("company");
    let new_width = `${this.state.containerWidth - buttonWidth - 100}`;
    let new_height = `${(new_width * 7.5) / 10}`;
    let new_svg_string = svg_string.replace(/<\/?p>/g, "");

    const bodyMatch = new_svg_string.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
    let extractedBody = bodyMatch ? bodyMatch[1] : new_svg_string;

    return (
      <div className="font-Calibri svg-content">
        <SVG
          src={extractedBody}
          width={new_width + "px"}
          height={new_height + "px"}
          title="Content"
        />
      </div>
    );
  };

  renderPublishSvgContent = (svg_string) => {
    let new_width = `${this.state.containerWidth - 60}`;
    let new_height = `${(new_width * 7.5) / 10}`;
    let new_svg_string = svg_string.replace(/<\/?p>/g, "");

    const bodyMatch = new_svg_string.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
    let extractedBody = bodyMatch ? bodyMatch[1] : new_svg_string;

    if (extractedBody?.search("<svg") !== -1) {
      extractedBody = extractedBody
        .replace(/aria-hidden="[^"]*"/, `aria-hidden="true"`)
        .replace(/width="[^"]*"/, `width="${new_width}px"`)
        .replace(/height="[^"]*"/, `height="${new_height}px"`)
        .replace(/style="[^"]*"/, `style="preserveAspectRatio='none'"`);

      return (
        <div className="font-Calibri svg-published">
          <SVG
            src={extractedBody}
            width={new_width + "px"}
            height={new_height + "px"}
            title="Content"
          />
        </div>
      );
    } else {
      return (
        <div className="font-Calibri published">
          <div
            style={{
              paddingTop: "30px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(extractedBody, {
                FORBID_TAGS: ["style"],
              }),
            }}
          />
        </div>
      );
    }
  };

  transform = (node) => {
    let content = JSON.parse(this.props.content);
    let buttonWidth = this.editButtonWidthRef?.current?.offsetWidth;

    if (node.type === "tag" && node.name === "custom") {
      if (
        content[node.attribs.classname] &&
        content[node.attribs.classname].type === "text"
      ) {
        return (
          <div className="d-flex">
            {!this.props.isDeleted && this.props.isEditable && (
              <div
                ref={this.editButtonWidthRef}
                className={"manage_content_action"}
              >
                <div className="d-flex mr-1">
                  <div
                    className="add-edit-btn ml-auto d-inline-flex align-items-center"
                    onClick={() => {
                      this.toggle(
                        node.attribs.classname,
                        content[node.attribs.classname].value,
                        content[node.attribs.classname].type
                      );
                    }}
                  >
                    <span className="material-icons-round icon-fs-14">
                      edit
                    </span>
                  </div>
                </div>
              </div>
            )}
            {this.state.isGroupdoc && buttonWidth ? (
              content[node.attribs.classname]?.value.trim().length > 0 &&
              this.renderSvgContent(
                buttonWidth,
                content[node.attribs.classname].value
              )
            ) : (
              <div className="mt-11px overflow-auto font-Calibri content">
                {content[node.attribs.classname]?.value.trim().length > 0 &&
                  this.renderPublishSvgContent(
                    content[node.attribs.classname].value
                  )}
              </div>
            )}

            <div className="mt-11px overflow-auto font-Calibri">
              {content[node.attribs.classname].value.trim().length === 0 &&
                this.props.isEditable && (
                  <p>{this.props.emptyContentMessage}</p>
                )}
            </div>
          </div>
        );
      } else if (
        content[node.attribs.classname] &&
        content[node.attribs.classname].type === "image" &&
        !Array.isArray(content[node.attribs.classname].value)
      ) {
        let prefix = "";
        let temp = content[node.attribs.classname].value
          ? content[node.attribs.classname].value
          : "";
        content[node.attribs.classname].value = temp;
        if (temp.startsWith("http")) {
          prefix = "";
        } else if (temp.startsWith("data:")) {
        } else prefix = process.env.REACT_APP_TBRI_IMAGE_URL;
        return (
          <>
            <div className="d-flex">
              {!this.props.isDeleted && this.props.isEditable && (
                <div
                  className={"manage_content_action"}
                  onClick={() => {
                    this.toggle(
                      node.attribs.classname,
                      content[node.attribs.classname].value,
                      content[node.attribs.classname].type
                    );
                  }}
                >
                  <div className="d-flex mr-1">
                    <div className="add-edit-btn ml-auto d-inline-flex align-items-center">
                      <span className="material-icons-round icon-fs-14">
                        edit
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-11px overflow-auto font-Calibri">
                {content[node.attribs.classname].value.trim().length > 0 && (
                  <img
                    style={this.styleConverter(
                      content[node.attribs.classname].style
                        ? content[node.attribs.classname].style
                        : ""
                    )}
                    src={`${prefix + content[node.attribs.classname].value}`}
                    alt={"content pic"}
                    className={"content_image"}
                  />
                )}
              </div>
              <div className="mt-11px overflow-auto font-Calibri">
                {content[node.attribs.classname].value.trim().length === 0 &&
                  this.props.isEditable && (
                    <p>{this.props.emptyContentMessage}</p>
                  )}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="d-flex align-items-start my-2">
              {!this.props.isDeleted && this.props.isEditable && (
                <div
                  className={
                    "manage_content_action add-content-icon mr-1 d-inline-flex"
                  }
                  onClick={() => {
                    this.toggle(
                      node.attribs.classname,
                      content[node.attribs.classname]?.value,
                      content[node.attribs.classname]?.type
                    );
                  }}
                >
                  <span className="material-icons-round icon icon-fs-14">
                    add
                  </span>
                </div>
              )}
              {this.props.isEditable && (
                <p className="mb-0 mt-2px font-Calibri">
                  {this.props.emptyContentMessage}
                </p>
              )}
            </div>
          </>
        );
      }
    }
  };

  parseHtml = (ele) => {
    let content = {};
    let template = this.props.template;

    try {
      content = JSON.parse(this.props.content);
      Object.keys(content).forEach((ind) => {
        if (content[ind].type) {
          const checkingTemplate = template.indexOf(ind);
          if (ind === "left_over_data" && checkingTemplate === -1) {
            template +=
              '<div class="row"><div class="col-lg-12">{left_over_data}</div><div class="col-lg-12">{left_over_images}</div></div>';
          }

          let replacer = "";
          replacer = `<div class='${
            this.props.isDeleted ? "content_deleted" : ""
          }'><custom className='${ind}' /></div>`;

          template = template.replace("{" + ind + "}", replacer);
        }
      });
      return this.emptyRemainingSlugs(template);
    } catch (e) {
      console.log("parse html error: ", e);
    }
  };

  handleUpdateJoditContent = (newValue) => {
    this.setState({ originalValue: newValue });
  };

  render() {
    return (
      <>
        <div ref={this.containerWidthRef}>
          {parse(this.parseHtml(), {
            replace: this.transform,
          })}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.closeModal}
          className={"modal-lg custom-modal primary-modal"}
        >
          <ModalHeader toggle={this.closeModal}>
            <h3 className="text-darkblue2 font-weight-bold">Add</h3>
          </ModalHeader>
          <ModalBody>
            <JoditEditorContainer
              height="calc(100vh - 280px)"
              value={this.state.originalValue}
              onChangeHandler={this.handleUpdateJoditContent}
            />
          </ModalBody>

          {this.state.modifiedValueType &&
            (this.state.modifiedValueType === "text" ||
              this.state.modifiedValueType === "image") && (
              <ModalFooter>
                <Button
                  className={"btn-outline-primary modal-btn"}
                  onClick={this.closeModal}
                >
                  Cancel
                </Button>{" "}
                <Button
                  className={"modal-right-btn modal-btn"}
                  onClick={this.saveModal1}
                >
                  Save
                </Button>
              </ModalFooter>
            )}
        </Modal>
      </>
    );
  }
}

export default withRouter(TemplateParser);
